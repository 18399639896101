import { Dispatch, FC, SetStateAction } from 'react';
import * as stylePC from './style.module.sass';
import * as styleSP from './style.sp.module.sass';
import { TApplicant, TMode, TSeminars } from '../types';
import dayjs from 'common/dayjs-with-timezone';
/* api */
import { postCancel } from '../api';

type TNext = {
  device: string;
  setMode: Dispatch<SetStateAction<TMode>>;
  setApplicant: Dispatch<SetStateAction<TApplicant>>;
  applicant: TApplicant;
  seminars: TSeminars;
};

const Confirmation: FC<TNext> = (props: TNext) => {
  const { device, setMode, applicant, seminars } = props;
  /**
   * 次へをクリック
   */
  const handleClickNext = async () => {
    await postCancel(applicant);
    setMode('completion');
  };
  const schedule = seminars.find((seminar) => seminar.id === applicant.seminar_schedule_id);
  const style = device === 'sp' ? styleSP : stylePC;

  return (
    <>
      <h1 className={style.heading}>キャンセル内容確認</h1>
      <div className={style.container}>
        <fieldset className={style.field}>
          <div className={style.item}>希望のコース</div>
          <div className={style.data}>{schedule?.course_detail_name}</div>
        </fieldset>
        <fieldset className={style.field}>
          <div className={style.item}>ご予約日時</div>
          <div className={style.data}>{dayjs(schedule?.start_at).format('YYYY.MM.DD(ddd) HH:mm~')}</div>
        </fieldset>
        <fieldset className={style.field}>
          <div className={style.item}>メールアドレス</div>
          <div className={style.data}>{applicant.email}</div>
        </fieldset>
        <div className={style.nav}>
          <button onClick={handleClickNext} className={style.button}>
            この予約をキャンセルする
          </button>
        </div>
      </div>
    </>
  );
};
export default Confirmation;
