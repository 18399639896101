// extracted by mini-css-extract-plugin
var _1 = "background__Bi1tn";
var _2 = "complete__ekMJ1";
var _3 = "complete__container__zFPJp";
var _4 = "complete__heading__zegEJ";
var _5 = "complete__icon__vvdBf";
var _6 = "complete__message__trnq0";
var _7 = "complete__subHeading__nQsTp";
var _8 = "container__notice__lVvX7";
var _9 = "links__NYmQ6";
var _a = "links__item__oY0vf";
export { _1 as "background", _2 as "complete", _3 as "complete__container", _4 as "complete__heading", _5 as "complete__icon", _6 as "complete__message", _7 as "complete__subHeading", _8 as "container__notice", _9 as "links", _a as "links__item" }
