export const DISPLAY_RANGE_DATE = 6;
export const PERIOD_END_MINUTE = 40;
export const SERVICE_START_HOUR = 9;
export const SERVICE_LAST_HOUR = 21;
export const RECOMMEND_PERIODS_MAP = [
  ['14:00', '17:00', '21:00'], // Sunday
  ['18:00', '20:00', '21:00'], // Monday
  ['18:00', '21:00'], // Tuesday
  ['10:00', '11:00', '14:00', '16:00', '18:00', '20:00', '21:00'], // Wednesday
  ['09:00', '14:00', '15:00', '21:00'], // Thursday
  ['09:00', '12:00', '13:00'], // Friday
  ['10:00', '11:00', '18:00'], // Saturday
];
export const CACHE_RESOURCE = 'https://s3-ap-northeast-1.amazonaws.com/codecamp-cache';
export const CURRICULUM_ID = 53;
export default {
  DISPLAY_RANGE_DATE,
  PERIOD_END_MINUTE,
  SERVICE_START_HOUR,
  SERVICE_LAST_HOUR,
  RECOMMEND_PERIODS_MAP,
  CACHE_RESOURCE,
  CURRICULUM_ID,
};
