import { useEffect } from 'react';
import * as holiday_jp from '@holiday-jp/holiday_jp';
import dayjs from 'common/dayjs-with-timezone';
import { constants } from '../../state/ducks';
import * as styleDesktop from './Timetable.module.sass';
import * as styleMobile from './TimetableSp.module.sass';

export default ({ timetable, reserveLimit, schedules, confirm, device, fetch }) => {
  const style = device.match(/sp/) ? styleMobile : styleDesktop;

  useEffect(() => fetch(), []);

  return (
    <div>
      {device.match(/sp/) && (
        <p className={style.explanation}>
          ◯=空き枠アリ、△=残枠わずか、
          <svg className={style.explanation__icon}>
            <use xlinkHref='#svg-icn_mail' />
          </svg>
          =お問い合わせ
        </p>
      )}
      <div className={style.container}>
        <div>
          <ul className={style.period}>
            <li className={style.period__item}>日時</li>
            {timetable[0].periods.map((period, key) => (
              <li className={style.period__item} key={key}>
                {period.locale.startAt}
                <span>
                  {device.match(/sp/) && <br />}〜{period.locale.endAt}
                </span>
              </li>
            ))}
            {device.match(/sp/) && <li className={style.period__item}>日時</li>}
          </ul>
        </div>
        <div className={style.container__content}>
          <h2 className={style.container__range}>
            {timetable[0].locale.long} 〜 {timetable[timetable.length - 1].locale.short}
          </h2>
          <ul className={style.timetable}>
            {timetable.map((date, key) => {
              // 日本の祝日判定
              const holiday = holiday_jp.isHoliday(new Date(date.date));
              return (
                <li className={style.timetable__perDay} key={key}>
                  <h3
                    className={!(date.weekday % 6) || holiday ? style.timetable__date__holiday : style.timetable__date}
                  >
                    {date.locale.day}
                    <span className={style.timetable__weekday}>{date.locale.weekday}</span>
                  </h3>
                  <ul className={style.lesson}>
                    {date.periods.map((period, key) => {
                      // 既に過ぎた時間
                      if (reserveLimit.isAfter(dayjs(period.datetime)))
                        return (
                          <li className={style.lesson__item} key={key}>
                            -
                          </li>
                        );

                      // 枠が未公開
                      if (typeof schedules[period.datetime] === 'undefined')
                        return (
                          <li className={style.lesson__item__empty} key={key}>
                            <a href={`/contact?counseling_request=1&request_period=${period.datetime}`}>
                              <svg className={style.lesson__icon}>
                                <use xlinkHref='#svg-icn_mail' />
                              </svg>
                            </a>
                          </li>
                        );

                      // 枠を公開中
                      const isRecommend = constants.RECOMMEND_PERIODS_MAP[date.weekday].includes(period.time);
                      return (
                        <li
                          className={isRecommend ? style.lesson__item__recommend : style.lesson__item__active}
                          key={key}
                        >
                          <div
                            className={style.lesson__button}
                            onClick={() => confirm(period)}
                            data-testid='lesson-button' // system specで使用
                          >
                            {isRecommend ? (
                              <svg className={style.lesson__icon}>
                                <use xlinkHref='#svg-icn_triangle' />
                              </svg>
                            ) : (
                              <svg className={style.lesson__icon}>
                                <use xlinkHref='#svg-icn_circle' />
                              </svg>
                            )}
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                  {device.match(/sp/) && (
                    <div
                      className={
                        !(date.weekday % 6) || holiday ? style.timetable__date__holiday : style.timetable__date
                      }
                    >
                      {date.locale.day}
                      <span className={style.timetable__weekday}>{date.locale.weekday}</span>
                    </div>
                  )}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
      {device.match(/pc/) && (
        <p className={style.explanation}>
          ◯=空き枠アリ、△=残枠わずか、
          <svg className={style.explanation__icon}>
            <use xlinkHref='#svg-icn_mail' />
          </svg>
          =お問い合わせ
        </p>
      )}
    </div>
  );
};
