import { connect } from 'react-redux';
import Template from '../components/Complete.jsx';

const mapStateToProps = (state) => {
  const {
    default: { complete, system },
  } = state;
  const userAgent = window.navigator.userAgent.toLowerCase();

  // 返却
  return {
    ...complete,
    ...system,
    isAndroid: userAgent.match(/android/),
  };
};

export default connect(mapStateToProps)(Template);
