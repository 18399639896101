// スクロールすると上からヘッダーが出て来る(コース詳細)
window.addEventListener('load', () => {
  const widgetObject = document.getElementById('js-fixedAnchor');
  const targetSection = document.getElementById('courses_target');

  if (!widgetObject || !targetSection) return;

  const SCROLL = targetSection.getBoundingClientRect().top + window.scrollY;

  document.addEventListener('scroll', () => {
    widgetObject.dataset.show = (window.scrollY > SCROLL).toString();
  });
});
