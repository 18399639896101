import types from './types.es6';
import fetch from 'common/fetch';

import { CURRICULUM_ID } from './constants.es6';

export const retrieve = () => {
  return (dispatch) => {
    fetch(`/lessons/schedules.json?course=${CURRICULUM_ID}`)
      .then((response) => response.json())
      .then((data) => {
        dispatch(insertSchedules(data));
      })
      .catch((error) => {
        console.error('Fetch error:', error);
      });
  };
};

const insertSchedules = (response) => ({
  type: types.INSERT_SCHEDULES,
  ...response,
});

const insertConfirm = (period) => ({
  type: types.INSERT_CONFIRM,
  period,
});

export const insertResult = (value) => ({
  type: types.INSERT_RESULT,
  value,
});

export const reserve = (email, url) => {
  return (dispatch) => {
    fetch(url, {
      method: 'POST',
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
      },
      body: JSON.stringify({ student: { email } }),
    })
      .then((response) => response.json())
      .then((body) => {
        return dispatch(insertResult({ ...body, method: 'email' }));
      });
  };
};

export const preReserve = (method, datetime, email, referrer) => {
  return (dispatch) => {
    fetch('/lessons/api/reserve', {
      method: 'POST',
      body: JSON.stringify({ method, datetime, curriculum_id: CURRICULUM_ID, referrer }),
    })
      .then((response) => response.json())
      .then((body) => {
        const { url, status } = body;
        if (status && method === 'email') return dispatch(reserve(email, url));

        return dispatch(insertResult(body));
      });
  };
};

export default {
  retrieve,
  reserve,
  preReserve,
  insertConfirm,
};
