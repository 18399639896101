// スクロールするとヘッダーの一部を固定
window.addEventListener('load', () => {
  // 固定する部分
  const fixedHeader = document.getElementById('js-fixedHeader');
  if (!fixedHeader) return;

  window.addEventListener('scroll', () => {
    fixedHeader.setAttribute('data-isFixed', (window.scrollY > 160 ? 1 : 0).toString());
  });
});
