import { createRoot } from 'react-dom/client';

const mount = (components: object) => {
  document.addEventListener('DOMContentLoaded', () => {
    Object.entries(components).forEach(([name, Component]) => {
      const nodes = document.querySelectorAll(`[data-react-class=${name}]`);

      nodes.forEach((node: Element) => {
        const propsJSON = node.getAttribute('data-react-props') as string;
        const props = JSON.parse(propsJSON);
        const root = createRoot(node);
        root.render(<Component {...props} />);
      });
    });
  });
};

export default mount;
