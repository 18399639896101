// import modules
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { constants } from '../../state/ducks';
import { ReservationContext } from '../../state/context';
import * as styleDesktop from './Complete.module.sass';
import * as styleMobile from './CompleteSp.module.sass';

export default ({ status, url, method, message, device, isAndroid }) => {
  const style = device.match(/sp/) ? styleMobile : styleDesktop;
  const { email } = useContext(ReservationContext);

  // 読み込み中
  if (status == null)
    return (
      <div className={style.background}>
        <div className={style.container}>
          {device.match(/pc/) && <h1 className={style.container__heading}>無料カウンセリング</h1>}
          <section className={style.container__contents}>
            <p className={style.container__notice}>現在処理中です</p>
          </section>
        </div>
      </div>
    );

  // 処理失敗
  if (status === false)
    return (
      <div className={style.background}>
        <div className={style.container}>
          {device.match(/pc/) && <Link to='/schedules/trial' className={style.container__close}></Link>}
          {device.match(/pc/) && <h1 className={style.container__heading}>エラーが発生しました</h1>}
          <section className={style.container__contents}>
            <div className={style.complete}>
              {device.match(/sp/) && (
                <p className={style.complete__heading}>無料カウンセリングの予約に失敗いたしました</p>
              )}
              <div className={style.complete__container}>
                <p className={style.container__notice}>{message}</p>
                {device.match(/sp/) && (
                  <ul className={style.links}>
                    <li className={style.links__item}>
                      <Link to='/schedules/trial' className='c-btn-neg c-btn-md c-btn-reverse'>
                        もどる
                      </Link>
                    </li>
                  </ul>
                )}
              </div>
            </div>
          </section>
        </div>
      </div>
    );

  // 成功時、emailの場合
  if (method === 'email')
    return (
      <div className={style.background}>
        <div className={style.container}>
          {device.match(/pc/) && <Link to='/schedules/trial' className={style.container__close}></Link>}
          {device.match(/pc/) && <h1 className={style.container__heading}>無料カウンセリング予約</h1>}
          <section className={style.container__contents}>
            <div className={style.complete}>
              <p className={style.complete__heading}>無料カウンセリングのご予約ありがとうございます</p>
              <div className={style.complete__container}>
                <img
                  src={`${constants.CACHE_RESOURCE}/services/lessons/icn_mail.png`}
                  className={style.complete__icon}
                />
                <h2 className={style.complete__subHeading}>メールをチェック!</h2>
                <p className={style.complete__message}>
                  {email}宛に
                  <br />
                  予約を完了するリンクを送信しました。
                  <br />
                  そのリンクから、お名前のご登録をお願いいたします。
                </p>
                {device.match(/pc/) && (
                  <ul className={style.links}>
                    <li className={style.links__item}>
                      <img
                        src={`${constants.CACHE_RESOURCE}/services/lessons/icn_gmail.png`}
                        className={style.links__icon}
                      />
                      <a
                        href='https://mail.google.com/mail'
                        target='_blank'
                        data-ccnt='Gmail導線-gmail'
                        rel='noreferrer'
                      >
                        Gmailを開く
                      </a>
                    </li>
                    <li className={style.links__item}>
                      <img
                        src={`${constants.CACHE_RESOURCE}/services/lessons/icn_micro.png`}
                        className={style.links__icon}
                      />
                      <a
                        href='https://outlook.live.com/mail'
                        target='_blank'
                        data-ccnt='Outlook導線-outlook'
                        rel='noreferrer'
                      >
                        Outlookを開く
                      </a>
                    </li>
                  </ul>
                )}
                {device.match(/sp/) && (
                  <ul className={style.links}>
                    <li className={style.links__item}>
                      <a
                        href={isAndroid ? 'android-app://com.google.android.gm' : 'message:'}
                        className='c-btn-cv c-btn-md'
                        data-ccnt='メールアプリ導線-spmail'
                      >
                        メールアプリを開く
                      </a>
                    </li>
                    <li className={style.links__item}>
                      <Link to='/schedules/trial' className='c-btn-neg c-btn-md c-btn-reverse'>
                        もどる
                      </Link>
                    </li>
                  </ul>
                )}
              </div>
            </div>
          </section>
        </div>
      </div>
    );

  // 成功時、OAuth認証の場合
  location.href = url;
  return null;
};
