// アコーディオン
window.addEventListener('load', () => {
  const trigger = document.querySelectorAll('#js-accordion > li');
  trigger.forEach((element) => {
    element.addEventListener('click', () => {
      const openFlg = element.getAttribute('data-openStatus') === 'true' ? 'false' : 'true';
      element.setAttribute('data-openStatus', openFlg);
    });
  });
});
