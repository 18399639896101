import { connect } from 'react-redux';
import { operations } from '../../state/ducks';
import { push } from 'react-router-redux';
import Template from '../components/Confirm.jsx';

const mapStateToProps = ({
  default: {
    register: { period },
    system,
  },
}) => {
  return {
    ...period,
    ...system,
  };
};

const mapDispatchToProps = (dispatch) => ({
  registerWithSNS: (method, datetime) => {
    dispatch(push('/schedules/trial/complete'));
    return dispatch(operations.preReserve(method, datetime));
  },
  onSubmit: (email, datetime, referrer) => {
    dispatch(push('/schedules/trial/complete'));
    return dispatch(operations.preReserve('email', datetime, email, referrer));
  },
});

const mergeProps = (state, action) => {
  const { datetime, referrer } = state;

  return {
    ...state,
    ...action,
    onSubmit: (values) => action.onSubmit(values, datetime, referrer),
    registerWithSNS: (method) => action.registerWithSNS(method, datetime),
  };
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(Template);
