import { FC, useContext } from 'react';
/* contexts */
import { DeviceContext } from '../contexts';
/* styles */
import * as stylePC from './style.pc.module.sass';
import * as styleSP from './style.sp.module.sass';

/**
 * @module Services/Components/CounselingFeedbackHeader
 */
const CounselingFeedbackHeader: FC = () => {
  const deviceContext = useContext(DeviceContext);
  const style = deviceContext === 'sp' ? styleSP : stylePC;

  return (
    <h2 className={style.counselingFeedbackHeader}>
      <img
        className='p-lesson-title__left'
        src='https://s3-ap-northeast-1.amazonaws.com/codecamp-cache/services/lessons/img_title_decoration_left.png'
      />
      無料カウンセリング
      {deviceContext === 'sp' ? <br /> : null}
      体験者の声
      <img
        className='p-lesson-title__right'
        src='https://s3-ap-northeast-1.amazonaws.com/codecamp-cache/services/lessons/img_title_decoration_right.png'
      />
    </h2>
  );
};

export default CounselingFeedbackHeader;
