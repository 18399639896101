// 汎用クリックイベント
// [data-clicktrigger]属性に(値)をセット。（トリガー）
// トリガーをクリック（タップ）すると[data-clickevent-(値)]属性を持つ要素(ターゲット）の[data-clickevent]をtrue or falseで切り替える。
// （初期状態で[data-clickevent]が設定されていない場合、クリックするとtrueに切り替わる）
//
// 例：
// button.trigger(data-clicktrigger='foo') 開く
// .wrapper.target(data-clickevent-foo)
//   ul
//     li コンテンツ
//     li コンテンツ
//   button.trigger(data-clicktrigger='foo') 閉じる

window.addEventListener('load', () => {
  document.body.addEventListener('click', (event: any) => {
    // トリガーを取得
    const trigger = event.target.closest('[data-clicktrigger]');
    if (!trigger) return;

    // トリガーに対応するターゲット名を取得
    const targetName = trigger.getAttribute('data-clicktrigger');
    // ターゲット一覧を取得
    const targets = document.querySelectorAll(`[data-clickevent-${targetName}]`);

    targets.forEach((target) => {
      const flg = target.getAttribute('data-clickevent') === 'true' ? 'false' : 'true';
      target.setAttribute('data-clickevent', flg);
    });
  });
});
