import actions from './actions.es6';

const fetch = actions.retrieve;
const confirm = actions.insertConfirm;
const preReserve = actions.preReserve;

export default {
  fetch,
  confirm,
  preReserve,
};
