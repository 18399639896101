import { configureStore } from '@reduxjs/toolkit';
import { reducer as formReducer } from 'redux-form';
import * as reducers from './ducks'; // import all reducers from ducks/index.js

const reducer = {
  ...reducers,
  form: formReducer,
};

const store = (initialState = {}) =>
  configureStore({
    reducer,
    preloadedState: initialState,
  });

export default store;
