import { FC, useContext } from 'react';
import dayjs from 'common/dayjs-with-timezone';
/* components */
import { DeviceContext } from '../contexts';
/* components */
import CounselingFeedbackSatisfaction from '../satisfaction';
/* styles */
import * as stylePC from './style.pc.module.sass';
import * as styleSP from './style.sp.module.sass';
/* types */
import { TFeedback } from '../types';

export type TCounselingFeedbackItem = {
  feedback: TFeedback;
};

/**
 * @module Services/Components/CounselingFeedbackItem
 */
const CounselingFeedbackItem: FC<TCounselingFeedbackItem> = ({ feedback }) => {
  const device = useContext(DeviceContext);
  const style = device === 'sp' ? styleSP : stylePC;
  const datetime = dayjs(feedback.datetime).format('YYYY年MM月DD日 HH時mm分');

  return (
    <div className={style.counselingFeedbackItem}>
      <div>
        <div className={style.counselingFeedbackItem__satisfactionHead}>
          <div className={style.counselingFeedbackItem__satisfactionLevel}>満足度</div>
          <div className={style.counselingFeedbackItem__satisfaction}>
            <CounselingFeedbackSatisfaction level={feedback.satisfactionLevel} />
          </div>
        </div>

        <div className={style.counselingFeedbackItem__message}>{feedback.message}</div>
      </div>
      <div className={style.counselingFeedbackItem__datetime}>
        <div className={style.counselingFeedbackItem__datetimeLabel}>カウンセリング日時</div>
        <div className={style.counselingFeedbackItem__datetimeData}>{datetime}</div>
      </div>
    </div>
  );
};

export default CounselingFeedbackItem;
