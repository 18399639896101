import { FC } from 'react';
/* components */
import CounselingFeedbackHeader from './header';
import CounselingFeedbackItems from './items';
/* contexts */
import { DeviceContext, FeedbacksContext } from './contexts';
/* types */
import { TDevice, TFeedback } from './types';

export type TCounselingFeedback = {
  device: TDevice;
  feedback: TFeedback[];
};

/**
 * @module Services/Components/CounselingFeedback
 */

const CounselingFeedback: FC<TCounselingFeedback> = ({ device, feedback }) => {
  return (
    <>
      <DeviceContext.Provider value={device}>
        <FeedbacksContext.Provider value={feedback}>
          <CounselingFeedbackHeader />
          <CounselingFeedbackItems />
        </FeedbacksContext.Provider>
      </DeviceContext.Provider>
    </>
  );
};

export default CounselingFeedback;
