// extracted by mini-css-extract-plugin
var _1 = "background__gEo_c";
var _2 = "container__dGQLX";
var _3 = "container__close__E2ObX";
var _4 = "container__heading__C5V_7";
var _5 = "contents__LHQNO";
var _6 = "contents__datetime__EneDa";
var _7 = "contents__step__mHldF";
var _8 = "contents__supplement__SCsR9";
var _9 = "contents__title__r7fXa";
var _a = "register__YWMqN";
var _b = "register__attention___WoV7";
var _c = "register__button__hupnS";
var _d = "register__consent__LtZag";
var _e = "register__email__Vtve7";
var _f = "register__formError__W8hhc";
var _10 = "register__item__OqsU1";
var _11 = "register__list__UM48Z";
var _12 = "register__otherwise__nb9Oy";
var _13 = "register__send__i5Epc";
var _14 = "register__title__do0YC";
export { _1 as "background", _2 as "container", _3 as "container__close", _4 as "container__heading", _5 as "contents", _6 as "contents__datetime", _7 as "contents__step", _8 as "contents__supplement", _9 as "contents__title", _a as "register", _b as "register__attention", _c as "register__button", _d as "register__consent", _e as "register__email", _f as "register__formError", _10 as "register__item", _11 as "register__list", _12 as "register__otherwise", _13 as "register__send", _14 as "register__title" }
