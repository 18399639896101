// extracted by mini-css-extract-plugin
var _1 = "counselingFeedbackItem__VVHkn";
var _2 = "counselingFeedbackItem__datetime__wfAky";
var _3 = "counselingFeedbackItem__datetimeData__vODpC";
var _4 = "counselingFeedbackItem__datetimeLabel__YN0Nm";
var _5 = "counselingFeedbackItem__message__zYn2k";
var _6 = "counselingFeedbackItem__satisfaction__PRrRw";
var _7 = "counselingFeedbackItem__satisfactionHead__V3XuW";
var _8 = "counselingFeedbackItem__satisfactionLevel__be3fn";
export { _1 as "counselingFeedbackItem", _2 as "counselingFeedbackItem__datetime", _3 as "counselingFeedbackItem__datetimeData", _4 as "counselingFeedbackItem__datetimeLabel", _5 as "counselingFeedbackItem__message", _6 as "counselingFeedbackItem__satisfaction", _7 as "counselingFeedbackItem__satisfactionHead", _8 as "counselingFeedbackItem__satisfactionLevel" }
