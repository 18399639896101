import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import validator from 'validator';

import { operations } from '../../state/ducks';
import Template from '../components/ContactForm';

const mapStateToProps = (state) => {
  const {
    default: { contact, counseling },
  } = state;

  return {
    ...contact,
    initialValues: {
      subject: counseling.request ? '無料カウンセリング希望' : '',
      contact: counseling.contact || '',
    },
  };
};

const validate = (values) => {
  const { name, email, subject, contact, check } = values;

  const errors = {
    name: name && validator.isLength(name, { min: 2, max: 255 }) ? null : '名前を入力してください',
    email: email && validator.isEmail(email) ? null : '正しい情報を入力して下さい',
    subject: !subject || validator.isLength(subject, { max: 65000 }) ? null : '件名が長すぎます', // 件名は任意
    contact:
      contact && validator.isLength(contact, { min: 2, max: 65000 }) ? null : 'お問い合わせ内容を入力してください',
    check: check ? null : '同意してください',
  };
  return errors;
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSubmit: (props) => {
      return dispatch(operations.contact(props));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm({
    form: 'contact',
    validate,
    destroyOnUnmount: false,
  })(Template),
);
