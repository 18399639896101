import { configureStore } from '@reduxjs/toolkit';
import { routerReducer, routerMiddleware } from 'react-router-redux';
import { reducer as formReducer } from 'redux-form';
import * as reducers from './ducks'; // import all reducers from ducks/index.js

const rootReducer = {
  ...reducers,
  routing: routerReducer,
  form: formReducer,
};

const store = (initialState = {}, history) =>
  configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => [...getDefaultMiddleware(), routerMiddleware(history)],
    preloadedState: initialState,
  });

export default store;
