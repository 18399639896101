import { connect } from 'react-redux';
import dayjs from 'common/dayjs-with-timezone';
import { operations } from '../../state/ducks';
import { push } from 'react-router-redux';
import Template from '../components/Timetable.jsx';

const mapStateToProps = ({ default: { schedules, system } }) => ({
  ...schedules,
  ...system,
  reserveLimit: dayjs().tz().startOf('hour').add(2, 'hour'),
});

const mapDispatchToProps = (dispatch) => ({
  fetch: () => dispatch(operations.fetch()),
  confirm: (period) => {
    dispatch(push('/schedules/trial/confirm'));
    dispatch(operations.confirm(period));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Template);
