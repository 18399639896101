import { useContext, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { constants } from '../../state/ducks';
import { ReservationContext } from '../../state/context';
import * as styleDesktop from './Confirm.module.sass';
import * as styleMobile from './ConfirmSp.module.sass';
/* components */
import AutoSuggestEmailForm from '../../../../../components/auto_suggest_email_form';
/* constants */
import { DOMAIN_LIST } from '../constants/dataset.es6';

const Template = ({ locale, registerWithSNS, onSubmit, device }) => {
  if (!locale) return null;

  const style = device.match(/sp/) ? styleMobile : styleDesktop;

  const [inputEmail, setInputEmail] = useState('');
  const [emptyEmail, setEmptyEmail] = useState(false);
  const [errorEmail, setErrorEmail] = useState(false);

  const { setEmail } = useContext(ReservationContext);

  const inputRef = useRef(null);

  /**
   * 入力フォームの強制スクロール
   */
  const scrollTop = () => {
    if (!device === 'sp') return;
    inputRef?.current?.scrollIntoView(true);
  };

  /**
   * 認証メールを送信ボタン をクリック
   */
  const onClickSubmit = () => {
    if (errorEmail || emptyEmail) return;

    setEmail(inputEmail);
    onSubmit(inputEmail);
  };

  const onClickGoogle = () => {
    const confirmCheck = confirm('無料カウンセリングの予約が完了します。よろしいでしょうか。');
    if (confirmCheck) {
      registerWithSNS('google');
    }
  };

  useEffect(() => {
    scrollTop();
  }, [inputEmail]);

  return (
    <div className={style.background}>
      <div className={style.container}>
        {device.match(/pc/) && <Link to='/schedules/trial' className={style.container__close}></Link>}
        <h1 className={style.container__heading}>会員登録後、予約が完了します</h1>
        <section className={style.contents}>
          <div className={style.contents__step}>
            <h2 className={style.contents__title}>ご予約日時</h2>
          </div>
          <div
            dangerouslySetInnerHTML={{
              __html: `${locale.date.replace(/(年|月|日)/g, '<span>$1</span>')} ${locale.timeShort}`,
            }}
            className={style.contents__datetime}
          />
          <p className={style.contents__supplement}>※カウンセリング当日の無断欠席はご遠慮頂いております</p>

          <div className={style.contents__step}>
            <h2 className={style.contents__title}>会員登録</h2>
          </div>
          <section className={style.register__section}>
            <p className={style.register__consent}>
              <a href='/rule' target='_blank' rel='noopener noreferrer'>
                利用規約
              </a>
              、
              <a href='/option_rule' target='_blank' rel='noopener noreferrer'>
                オプション利用規約
              </a>
              、
              <a href='/privacy_policy' target='_blank' rel='noopener noreferrer'>
                プライバシーポリシー
              </a>
              及び
              <a href='/careerup_specialcontract' target='_blank' rel='noopener noreferrer'>
                経済産業省「リスキリングを通じたキャリアアップ支援事業」対象コースに関する特約
              </a>
              に同意の上、会員登録をしてください。
            </p>
            <ul className={style.register__list}>
              <li>
                <div onClick={onClickGoogle} className={style.register__item}>
                  <img
                    src={`${constants.CACHE_RESOURCE}/products/project/sessions/icon_google.svg`}
                    width='30'
                    height='30'
                  />
                  上記に同意してGoogleで登録する
                </div>
              </li>
              {/* // 2022/08/03 OmniAuth アカウントが無効な状態のため暫定対応 */}
              {/* <li>
                <div onClick={() => registerWithSNS('yahoo')} className={style.register__item}>
                  <img
                    src={`${constants.CACHE_RESOURCE}/products/project/sessions/icon_yahoo.png`}
                    width='30'
                    height='30'
                  />
                  Yahooアカウントで登録する
                </div>
              </li> */}
              {/* // 2022/08/03 OmniAuth アカウントが無効な状態のため暫定対応 */}
            </ul>
            <p className={style.register__otherwise}>または</p>
          </section>
          <section className={style.register__section}>
            {!emptyEmail && errorEmail && <p className={style.register__formError}>※メールアドレスが不正です</p>}
            {emptyEmail && <p className={style.register__formError}>※メールアドレスを入力してください</p>}

            <div ref={inputRef} className={style.register__email}>
              <AutoSuggestEmailForm
                inputValue={inputEmail}
                setInputValue={setInputEmail}
                setEmptyEmail={setEmptyEmail}
                setErrorEmail={setErrorEmail}
                placeholderText='メールアドレスを入力してください'
                domainDataSet={device.match(/pc/) ? DOMAIN_LIST : []}
                device={device}
              />
            </div>

            <div className={style.register__button}>
              <button onClick={onClickSubmit} disabled={errorEmail || emptyEmail} className={style.register__send}>
                上記に同意してメールで送信する
              </button>
            </div>
          </section>

          {device.match(/sp/) && (
            <div className={style.container__close}>
              <Link to='/schedules/trial' className={style.container__closeButton}>
                戻る
              </Link>
            </div>
          )}
        </section>
      </div>
    </div>
  );
};

export default Template;
