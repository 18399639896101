import dayjs from 'common/dayjs-with-timezone';
import range from 'number-array-generator';
import { combineReducers } from '@reduxjs/toolkit';
import { DISPLAY_RANGE_DATE, PERIOD_END_MINUTE, SERVICE_START_HOUR, SERVICE_LAST_HOUR } from './constants.es6';
import types from './types.es6';

/**
 *  タイムテーブルの生成
 *  @version 2017/01/05
 *  @author ryo-as
 */
const timetable = () =>
  range(0, DISPLAY_RANGE_DATE).map((day) => {
    const date = dayjs().tz().startOf('day').add(day, 'day');
    return {
      date: date.format('YYYY-MM-DD'),
      weekday: date.weekday(),
      locale: {
        day: date.format('D'),
        short: date.format('MM/DD'),
        long: date.format('YYYY/MM/DD'),
        weekday: date.format('(dddd)'),
      },
      periods: range(SERVICE_START_HOUR, SERVICE_LAST_HOUR).map((hour) => {
        const period = date.hour(hour);
        return {
          datetime: period.format('YYYY-MM-DD HH:mm:ss'),
          date: period.format('YYYY-MM-DD'),
          time: period.format('HH:mm'),
          locale: {
            date: period.format('YYYY年MM月DD日'),
            startAt: period.format('HH:mm'),
            endAt: period.format(`HH:${PERIOD_END_MINUTE}`),
            timeLong: `${period.format('HH時mm分')} 〜 ${period.format(`HH時${PERIOD_END_MINUTE}分`)}`,
            timeShort: `${period.format('HH:mm')}〜${period.format(`HH:${PERIOD_END_MINUTE}`)}`,
          },
        };
      }),
    };
  });

const initialState = {
  timetable: timetable(),
  schedules: {},
  current: {},
};

const schedules = (state = initialState, action) => {
  switch (action.type) {
    case types.INSERT_SCHEDULES:
      return {
        ...state,
        current: action.current,
        schedules: action.schedules,
      };

    default:
      return state;
  }
};

const register = (state = {}, action) => {
  switch (action.type) {
    case types.INSERT_CONFIRM:
      return { period: action.period };

    default:
      return state;
  }
};

const complete = (
  state = {
    status: null,
    url: null,
    method: null,
    message: null,
  },
  action,
) => {
  switch (action.type) {
    case types.INSERT_RESULT:
      return action.value;

    default:
      return state;
  }
};

const system = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const reducer = combineReducers({
  schedules,
  register,
  complete,
  system,
});

export default reducer;
