window.addEventListener('load', () => {
  // トリガー
  const trigger = document.querySelector('[data-hidden]');
  if (!trigger) return;
  const footer = document.getElementById('js-footer');
  if (!footer) return;

  // コンテンツ全体の高さ
  const CONTENT_HEIGHT = document.body.offsetHeight;
  // ウィンドウの高さ
  const WINDOW_HEIGHT = window.innerHeight;
  // フッターの高さ
  const FOOTER_HEIGHT = footer.clientHeight;
  // 非表示にするOFFSET
  const OFFSET_POINT = CONTENT_HEIGHT - WINDOW_HEIGHT - FOOTER_HEIGHT;

  // スクロールイベント
  window.addEventListener('scroll', () => {
    // ページの最初と最後は表示しない
    const hideFlg = window.scrollY > WINDOW_HEIGHT * 0.7 && window.scrollY < OFFSET_POINT ? 'false' : 'true';
    trigger.setAttribute('data-hidden', hideFlg);
  });
});
