import { createContext, useState } from 'react';

export const ReservationContext = createContext('');

const ReservationContextProvider = ({ children }) => {
  const [email, setEmail] = useState('');

  return <ReservationContext.Provider value={{ email, setEmail }}>{children}</ReservationContext.Provider>;
};

export default ReservationContextProvider;
