import { useState } from 'react';
import Autosuggest from 'react-autosuggest';
import validator from 'validator';
/* styles */
import * as stylePC from './AutoSuggestEmailForm.pc.module.sass';
import * as styleSP from './AutoSuggestEmailForm.sp.module.sass';
import ReactAutosuggestStyle from './ReactAutosuggestStyle';

/**
 * @module Components/AutoSuggestEmailForm
 */
const AutoSuggestEmailForm = ({
  inputValue,
  setInputValue,
  setEmptyEmail, // Optional Function
  setErrorEmail, // Optional Function
  domainDataSet,
  placeholderText,
  device,
}) => {
  const [suggestions, setSuggestions] = useState([]);

  const style = device.match(/sp/) ? styleSP : stylePC;

  /**
   * サジェストを読み込み
   */
  const onSuggestionsFetchRequested = ({ value }) => {
    const account = value.match('@') ? value.substring(0, value.indexOf('@')) : value;
    setSuggestions(domainDataSet.map((domain) => account + domain));
  };

  /**
   * サジェストをクリア
   */
  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  /**
   * サジェストを取得
   */
  const getSuggestionValue = (suggestion) => {
    return suggestion;
  };

  /**
   * サジェストをレンダリング
   */
  const renderSuggestion = (suggestion) => {
    return <div>{suggestion}</div>;
  };

  /**
   * 全角文字が含まれているかチェック
   */
  const isErrorEmailCheck = (email) => {
    return !validator.isEmail(email) || /[^\x00-\xff]/.test(email);
  };

  /**
   * インプットフォームを入力
   */
  const onChangeInput = (event, { newValue }) => {
    if (!event) return;

    setInputValue(newValue);
    if (setEmptyEmail) setEmptyEmail(!newValue);
    if (setErrorEmail) setErrorEmail(isErrorEmailCheck(newValue));
  };

  /**
   * フォームデータ設定
   */
  const inputProps = {
    value: inputValue,
    onChange: onChangeInput,
    className: style.autoSuggestEmailForm__form,
    placeholder: placeholderText,
  };

  return (
    <>
      <ReactAutosuggestStyle />
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={inputProps}
      />
    </>
  );
};

export default AutoSuggestEmailForm;
