import fetch from 'common/fetch';
import { TApplicant, TRes } from './types';

export const postCancel = async (applicant: TApplicant): Promise<boolean> => {
  const res = await fetch('/seminar', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      id: applicant.id,
    }),
  });

  const data = await res.json();

  return data.data;
};
export const postInput = async (applicant: TApplicant): Promise<TRes> => {
  const res = await fetch('/next/search', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      reservation_number: applicant.reservation_number,
      email: applicant.email,
    }),
  });

  const data = await res.json();

  return data;
};
