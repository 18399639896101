/* Asia/Tokyo のタイムゾーン情報を付与して Dayjs をラップ */
import dayjs, { Dayjs } from 'dayjs';
import updateLocale from 'dayjs/plugin/updateLocale';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import duration from 'dayjs/plugin/duration';
import minMax from 'dayjs/plugin/minMax';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import weekday from 'dayjs/plugin/weekday';

import 'dayjs/locale/ja';

const LOCALE_DAY_OF_WEEK = ['日', '月', '火', '水', '木', '金', '土'];

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(updateLocale);
dayjs.extend(duration);
dayjs.extend(minMax);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(weekday);

dayjs.locale('ja');
dayjs.updateLocale('ja', { weekdays: LOCALE_DAY_OF_WEEK });

dayjs.tz.setDefault('Asia/Tokyo');

// NOTE: 日本時間を利用したいときには dayjs().tz() のように都度 tz() を呼ぶ必要がある

export default dayjs;
export type { Dayjs };
