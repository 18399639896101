import { FC, useContext } from 'react';
import { Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
/* components */
import { DeviceContext, FeedbacksContext } from '../contexts';
/* components */
import CounselingFeedbackItem from '../item';
/* constants */
import { MAX_SP_FEEDBACKS_COUNT } from '../constants';
/* styles */
import * as stylePC from './style.pc.module.sass';
import * as styleSP from './style.sp.module.sass';
import 'swiper/css';
import 'swiper/css/pagination';

/**
 * @module Services/Components/CounselingFeedbackItems
 */
const CounselingFeedbackItems: FC = () => {
  const device = useContext(DeviceContext);
  const feedbacks = useContext(FeedbacksContext);

  const style = device === 'sp' ? styleSP : stylePC;
  const data = device === 'sp' ? feedbacks.slice(0, MAX_SP_FEEDBACKS_COUNT) : feedbacks;
  const slidesPerView = device === 'sp' ? 1 : feedbacks.length;

  return (
    <div className={style.counselingFeedbackItems}>
      <Swiper
        modules={[Pagination]}
        {...{ slidesPerView }}
        spaceBetween={20}
        pagination={{ clickable: true, el: '#swiper-pagination' }}
        scrollbar={{ draggable: true }}
      >
        {data.map((v, i) => (
          <SwiperSlide key={i}>{v.message && v.datetime && <CounselingFeedbackItem feedback={v} />}</SwiperSlide>
        ))}
      </Swiper>
      <div id='swiper-pagination' className='u-mt-10 u-ta-center' />
    </div>
  );
};

export default CounselingFeedbackItems;
