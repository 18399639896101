import { Provider } from 'react-redux';
import { Router } from 'react-router';
import { createBrowserHistory } from 'history';
import configureStore from './state/store.es6';
import ReservationContextProvider from './state/context';
import App from './views/App.jsx';

const history = createBrowserHistory();

const Reservation = (props) => {
  const store = configureStore({ default: { system: { ...props } } }, history);

  return (
    <Provider {...{ store }}>
      <ReservationContextProvider>
        <Router {...{ history }}>
          <App />
        </Router>
      </ReservationContextProvider>
    </Provider>
  );
};

export default Reservation;
