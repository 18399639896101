/**
 * @module Components/AutoSuggestEmailForm/Style
 */
const ReactAutosuggestStyle = () => {
  const css = `
    .react-autosuggest__container {
      position: relative;
    }
    
    .react-autosuggest__input {
      width: 240px;
      height: 30px;
      padding: 10px 20px;
      font-family: Helvetica, sans-serif;
      font-weight: 300;
      font-size: 16px;
      border: 1px solid #aaa;
      border-radius: 4px;
      text-align: left
    }
    
    .react-autosuggest__input--focused {
      outline: none;
    }
    
    .react-autosuggest__input--open {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
    
    .react-autosuggest__suggestions-container {
      display: none;
    }
    
    .react-autosuggest__suggestions-container--open {
      display: block;
      position: absolute;
      top: 51px;
      border: 1px solid #aaa;
      background-color: #fff;
      font-family: Helvetica, sans-serif;
      font-weight: 300;
      font-size: 16px;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      z-index: 2;
    }
    
    .react-autosuggest__suggestions-list {
      margin: 0;
      padding: 0;
      list-style-type: none;
    }
    
    .react-autosuggest__suggestion {
      cursor: pointer;
      padding: 4px 20px;
      text-align: left;
    }
    
    .react-autosuggest__suggestion--highlighted {
      background-color: #ddd;
    }
  `;

  return <style>{css}</style>;
};

export default ReactAutosuggestStyle;
