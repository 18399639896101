import Rails from '@rails/ujs';
Rails.start();
import 'components/bugsnag.es6';
import mount from 'components/mount';

import Alert from 'components/alert';
import Contact from 'services/components/contact';
// エンジニア転職エントリーフォーム
import CounselingFeedback from 'services/components/counseling_feedback';
import Reservation from 'services/components/reservation';
import SeminarCancel from 'services/components/seminar_cancel/application';
import DivisionModal from 'services/components/division_modal';

mount({ Alert, Contact, CounselingFeedback, Reservation, SeminarCancel, DivisionModal });

// スクロールするとヘッダーの高さを変更
import 'products/scripts/fixed_header';
// // SPのメニュー開く
import 'scripts/drawer';
import 'scripts/accordion';
// コース詳細用のヘッダー
import 'services/scripts/fixed_anchor';
// 透過フッターの表示・非表示
import 'scripts/footer_fadeout';
// 汎用クリックイベント
import 'scripts/click_event';
