// extracted by mini-css-extract-plugin
var _1 = "background__hhYH6";
var _2 = "complete__LUx0x";
var _3 = "complete__heading__D6UMh";
var _4 = "complete__icon__NK6cI";
var _5 = "complete__message__epvrP";
var _6 = "complete__subHeading__XemRK";
var _7 = "container__wOg6o";
var _8 = "container__close__vz2wB";
var _9 = "container__contents__tYQ5d";
var _a = "container__heading___VBwY";
var _b = "container__notice__wabY2";
var _c = "links__TJunm";
var _d = "links__icon__QOmEh";
var _e = "links__item__fBh_M";
export { _1 as "background", _2 as "complete", _3 as "complete__heading", _4 as "complete__icon", _5 as "complete__message", _6 as "complete__subHeading", _7 as "container", _8 as "container__close", _9 as "container__contents", _a as "container__heading", _b as "container__notice", _c as "links", _d as "links__icon", _e as "links__item" }
