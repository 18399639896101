window.addEventListener('load', () => {
  // ドロワーメニュー
  const body = document.body;
  const triggers = document.querySelectorAll('.js-drawerOpen');
  // メガメニュー
  const megaMenus = document.querySelectorAll('.js-megaMenuOpen');
  // メガメニュー閉じるボタン
  const returnButtons = document.querySelectorAll('.js-megaMenuClose');

  // ドロワーメニュー開く
  triggers.forEach((trigger) => {
    trigger.addEventListener('click', () => {
      body.dataset.menuOpen = body.dataset.menuOpen === 'true' ? 'false' : 'true';
      // ドロワー閉じたらメガメニューを閉じる
      megaMenus.forEach((megaMenu) => {
        megaMenu.setAttribute('data-megaMenuOpen', 'false');
      });
    });
  });

  // メガメニュー開く
  megaMenus.forEach((megaMenu) => {
    megaMenu.addEventListener('click', () => {
      const flg = !(megaMenu.getAttribute('data-megaMenuOpen') === 'true');
      megaMenu.setAttribute('data-megaMenuOpen', flg.toString());
    });
  });

  // メガメニュー閉じる（メニュートップに戻る）
  returnButtons.forEach((returnButton) => {
    returnButton.addEventListener('click', () => {
      megaMenus.forEach((megaMenu) => {
        megaMenu.setAttribute('data-megaMenuOpen', 'false');
      });
    });
  });
});
